import * as React from 'react'
import { title } from '../components/my-module.css'

const Card = () => {
  return (
    <h1 className={title}>
      Super Sweet Title Page
    </h1>
  )
}

export default Card