import React from 'react'
import Frame from '../components/card'

const CardGalleryPage = () => {
  return (
    <Frame>
      <p>This will be passed in as children</p>
    </Frame>
  )
}

export default CardGalleryPage